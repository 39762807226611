/* newModal.module.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black for overlay */
  backdrop-filter: blur(8px); /* Adds a subtle blur effect */
  -webkit-backdrop-filter: blur(8px); /* Ensure compatibility with Safari */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in effect */
}


.modal {
  background: #fff;
  border-radius: 12px; /* Smooth rounded corners */
  box-shadow: 
    0 8px 16px rgba(0, 0, 0, 0.2), /* Subtle shadow for depth */
    0 0 30px rgba(58, 123, 213, 0.5), /* Blue outer glow */
    0 0 60px rgba(58, 123, 213, 0.4); /* Softer outer glow */
  width: 90%;
  max-width: 500px;
  overflow: hidden;
  transform: translateY(50px);
  animation: slideIn 0.3s ease-in-out forwards;
}

.header {
  background: #f5f5f5; /* Light gray background for the header */
  padding: 16px 24px;
  border-bottom: 1px solid #ddd; /* Subtle separator line */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: #666;
  cursor: pointer;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: #e74c3c; /* Red color on hover */
}

.content {
  padding: 24px;
  font-size: 1rem;
  color: #555; /* Neutral text color */
  line-height: 1.5;
}

.slideUp {
  animation: slideIn 0.3s ease-in-out forwards;
}

.slideDown {
  animation: slideOut 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(50px);
    opacity: 0;
  }
}
